<template>
  <div class="player">
    <p class="audioTimePosition">
      <span
        id="playedTime"
        class="playedTime"
        :style="{ left: (playedTime / audioDuration) * wavesurferWidth + 'px' }"
      >
        {{ playedTime | delayDuration }}
      </span>
    </p>
    <div class="right-bottom">
      <div class="bottom1">
        <i
          v-if="playOrNot"
          class="iconfont icon-bofang"
          style="font-size: 24px"
          @click="playAudio"
        ></i>
        <i
          v-if="!playOrNot"
          class="iconfont icon-liebiaozanting"
          style="font-size: 24px"
          @click="playAudio"
        ></i>
      </div>
      <div class="bottom2">
        <p class="timeLine">
          <span
            :style="{
              left: (playedTime / audioDuration) * wavesurferWidth - 2 + 'px',
            }"
          ></span>
        </p>
        <div
          id="wavesurfer"
          style="z-index: 0 !important"
          @click="getPosition($event)"
        ></div>
        <span>{{ audioDuration | delayDuration }}</span>
      </div>
      <div class="bottom3" style="z-index: 9 !important">
        <div v-show="sliderShow" class="slider" style="height: 114px">
          <a-slider vertical :default-value="50" @change="change" @afterChange="afterChange"/>
        </div>
        <span @click="showSlider">
          <i
            class="iconfont icon-icon_vio_24"
            style="z-index: 10 !important"
          >
          </i>
        </span>
        <span
          v-if="!collectionTip"
          class="middle-span"
          @click="collectSongOrNot('y')"
        >
          <i
            class="iconfont icon-icon_leftbar_collect_unselect_241"
            style="font-size: 22px; z-index: 10 !important"
          >
          </i>
        </span>
        <span
          v-if="collectionTip"
          class="middle-span"
          @click="collectSongOrNot('n')"
        >
          <i
            class="iconfont icon-icon_leftbar_collect_select_241"
            style="font-size: 22px; color: #d36666; z-index: 10 !important"
          >
          </i>
        </span>
        <span class="downLoadFile" @click.stop="downloadFile($event)">
          <i  v-if="downloadORloading"
            class="iconfont icon-icon_playbar_download_24"
            style="z-index: 10 !important"
          >
          </i>
          <img v-if="!downloadORloading"
             src="../assets/audioLoading.gif"/>
        </span>
      </div>
    </div>
    <div
      class="burstPoint-content"
      v-if="burstPointTime > 0"
      :style="{ width: wavesurferWidth + 'px' }"
    >
      <div class="structureTimeArr">
        <span
          v-for="(item, index) in structureTimearr"
          :key="index"
          :style="{
            width:
              ((structureTimearr[index] - structureTimearr[index - 1]) /
                audioDuration) *
                wavesurferWidth -
              2 +
              'px',
          }"
        ></span>
      </div>
      <div class="burstPoint-aboutTime">
        <p class="burstPoint-tip" v-if="burstPointTip">拖动修改高能点位置</p>
        <span
          class="burstPoint-img"
          @mouseover="changeBurstPointTipOver"
          @mouseleave="burstPointTip = false"
          :style="{ left: leftLocation - 11 + 'px' }"
          v-drag
        >
          <i class="iconfont icon-shixinhuomiao"></i> </span
        ><br />
        <span
          v-show="burstPointTimeTip & playOrNot2"
          class="aboutTime-time1"
          :style="{ left: leftLocation - 35 + 'px' }"
        >
          <i class="iconfont icon-shijian"></i>
          {{ burstPointtime | delayDuration }}
        </span>
        <span
          v-show="!burstPointTimeTip"
          class="aboutTime-time2"
          :style="{ left: leftLocation - 48 + 'px' }"
          @click="createMusic"
        >
          <span id="animationn">
            <i class="iconfont icon-zhongxin1"></i>
          </span>
          重新渲染
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { send_record } from "../utils/umeng_util";
import { axiosGet, axiosPost } from "../../src/axios.js";
import WaveSurfer from "wavesurfer.js";
import {
  FAVORITEORNOT,
} from "../../src/api.js";
export default {
  props: {
    musicName: String,
    wavesurferAdr: String,
    burstPointTime: Number,
    musicId: Number,
    structureTimeArr: Array,
    burstConArr: Array, // 可移动范围
    structurePowerArr: Array,
    renderTip: Boolean, // 重复渲染的时候，渲染过程中不可点击
  },
  data: () => ({
    burstPointtime: -1, // 父组件传递的高能点时间
    structureTimearr: [],
    leftLocation: -1, // 与高能点相关的位置

    burstPointTip: false, // 拖动修改高能点位置 是否显示
    burstPointTimeTip: true, // 播放器最下方提示
    saveBurstPointtime: -1,
    colorArr: ["#A2E0FB", "#68B6FE", "#4477FA", "#0039A8"], // 背景颜色数组
    wavesurfer: null, // 波形图对象
    audioDuration: 0, // 音频时长
    playedTime: 0, // 已播放时长
    wavesurferWidth: 0, // 波形图宽度
    playOrNot: false, // 是否播放音乐
    playOrNot2: false, // 波形图未渲染完成不可以点击播放
    sliderShow: false, // 音量控制是否显示


    collectionTip: Boolean,

    downloadORloading: true //true download false loading
  }),
  filters: {
    delayDuration(dur) {
      const duration = Math.round(dur);
      const min = Math.floor(duration / 60);
      const sec = duration % 60 > 9 ? duration % 60 : "0" + (duration % 60);
      return min + ":" + sec;
    },
  },
  directives: {
    drag(el, binding, vnode) {
      let dragBox = el;
      // vnode.context 是整个vue实例 相当于this
      let that = vnode.context;
      dragBox.onmousedown = (e) => {
        if (!that.renderTip) return;

        that.burstPointTimeTip = true;
        that.burstPointTip = false;
        //算出鼠标相对元素的位置
        let disX = e.clientX - dragBox.offsetLeft - 10;
        document.onmousemove = (e) => {
          that.burstPointTip = false;
          //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
          that.leftLocation =
            e.clientX - disX >
            (that.wavesurferWidth * that.$props.burstConArr[1]) / that.audioDuration ? (that.wavesurferWidth * that.$props.burstConArr[1]) / that.audioDuration
              : e.clientX - disX < (that.wavesurferWidth * that.$props.burstConArr[0]) / that.audioDuration ? (that.wavesurferWidth * that.$props.burstConArr[0]) / that.audioDuration
              : e.clientX - disX;
        };
        document.onmouseup = (e) => {
          that.burstPointTimeTip = false;
          //鼠标弹起来的时候不再移动
          document.onmousemove = null;
          //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
          document.onmouseup = null;
        };
      };
    },
  },
  watch: {
    sliderShow: function(val, oldVal) {
      this.$emit("changeSliderShow", val)
    },  
    wavesurferAdr: function (val, oldVal) {
      this.getWidth();
      this.createWaveform();
      this.playedTime = 0;
      this.downloadORloading = true;
    },
    leftLocation: function (val, oldVal) {
      this.burstPointtime = (val * this.audioDuration) / this.wavesurferWidth;
      if(Math.round(this.burstPointtime*100) == Math.round(this.$props.burstPointTime*100)) {
        this.burstPointTimeTip = true;
      }
    },
    structureTimeArr: {
      handler(val, oldVal) {
        this.structureTimearr = val;
      },
      deep: true,
    },
    burstPointTimeTip: function (val, oldVal) {
      if (!val) {
        let time = (this.audioDuration / this.wavesurferWidth) * 7;
        let moveTime = this.findNumber(
          this.structureTimearr,
          this.burstPointtime
        );
        if (Math.abs(moveTime - this.burstPointtime) <= time) {
          this.leftLocation =
            (this.wavesurferWidth / this.audioDuration) * moveTime;
        }
        if (this.saveBurstPointtime == this.BurstPointtime) {
        }
      }
    },
  },
  beforeMount() {
    this.burstPointtime = this.$props.burstPointTime;
    this.saveBurstPointtime = this.burstPointtime
    this.structureTimearr = this.$props.structureTimeArr;
    // this.setCollectionTip();
  },
  mounted() {
  },
  methods: {
    // 获取宽度
    getWidth() {
      this.$nextTick(()=> {
        let wavesurfer = document.getElementById("wavesurfer");
        this.wavesurferWidth = wavesurfer.offsetWidth;
        this.leftLocation =
          (this.$props.burstPointTime / this.audioDuration) *
          this.wavesurferWidth;
      })
    },
    findNumber(arr, target) {
      let result = arr[0];
      for (let i = 1, len = arr.length; i < len; i++) {
        if (Math.abs(arr[i] - target) < Math.abs(result - target)) {
          result = arr[i];
        }
      }
      return result;
    },
    setBackgroundColor() {
      this.$nextTick(() => {
        let parent =
          document.getElementsByClassName("structureTimeArr")[0].children;
        let setBackDomArr = [];
        for (let i = 0; i < parent.length; i++) {
          if (i >= 1) {
            setBackDomArr.push(parent[i]);
          }
        }
        for (let j = 0; j < setBackDomArr.length; j++) {
          setBackDomArr[j].style.background =
            this.colorArr[this.$props.structurePowerArr[j] - 1];
        }
      });
    },
    createMusic() {
      send_record("generate_re_generate_music_button", {boom_time: this.burstPointtime});
      this.$emit("createAgain", this.burstPointtime);
      this.$props.renderTip = false;
      document.getElementById("animationn").className = "animationn";
    },
    move() {
      this.$props.renderTip = true;
      if (document.getElementById("animationn") != null) {
        document.getElementById("animationn").className = "";
      }
    },
    changeBurstPointTipOver() {
      this.burstPointTip = true;
      let styleLeft =
        document.getElementsByClassName("burstPoint-img")[0].style.left;
      let left = Number(styleLeft.substring(0, styleLeft.length - 2));
      this.$nextTick(() => {
        document.getElementsByClassName("burstPoint-tip")[0].style.left =
          left - 50 + "px";
      });
    },
    setCollectionTip() {
      let href = window.location.href;
      if (href.indexOf("/home") > -1) {
        this.collectionTip = false;
      } else if (href.indexOf("/collection") > -1) {
        this.collectionTip = true;
      }
    },
    destoryWave() {
      if (this.wavesurfer != null) {
        if(this.wavesurfer.isPlaying()) {
          this.wavesurfer.playPause();
          this.playOrNot = false
          this.$emit("changePlayAudioFlag", this.playOrNot);
        } else {
          return
        }
      }
    },
    // 画波形图
    createWaveform() {
      this.playOrNot = false;
      this.playOrNot2 = false;
      this.burstPointTimeTip = true;
      this.$nextTick(() => {
        if (document.getElementsByTagName("wave").length === 0) {
          this.wavesurfer = WaveSurfer.create({
            container: "#wavesurfer",
            waveColor: "#BCBCBC",
            height: 43,
            barWidth: 1,
            progressColor: "#000000",
            barGap: 2,
            cursorColor: "rgba(0, 0, 0, 0.3)",
          });
        }
        this.wavesurfer.load(this.wavesurferAdr);

        this.wavesurfer.on("finish", () => {
          this.playOrNot = false;
          this.$emit("changePlayAudioFlag", this.playOrNot);
        });
        this.wavesurfer.on("ready", () => {
          this.move();
          this.playOrNot2 = true;
          this.$emit("loadFinish");
          this.audioDuration = this.wavesurfer.getDuration();
          this.structureTimearr.push(this.audioDuration);
          this.setBackgroundColor();
          this.getWidth();
          this.wavesurfer.setVolume(0.5);
          if(localStorage.getItem("cancelFavoriteList") == null) {
            if(window.location.href.indexOf("collection") > -1)
            this.collectionTip = true;
            return;
          }
            let arr = localStorage.getItem("cancelFavoriteList").split(",");
            let index = arr.findIndex(items => items == this.$props.musicId);
            if(index > -1) {
                this.collectionTip = false;
            } else {
                this.collectionTip = true;
            }
        });
        this.wavesurfer.on("audioprocess", () => {
          this.playedTime = this.wavesurfer.getCurrentTime();
        });
      });
    },
    // 播放暂停音频
    playAudio() {
      if (!this.playOrNot2) {
        return;
      }
      this.wavesurfer.playPause();
      this.playOrNot = !this.playOrNot;

      this.$emit("changePlayAudioFlag", this.playOrNot);
    },
    // 音量控制滑块是否显示
    showSlider() {
      if (!this.sliderShow) {
        this.sliderShow = true;
      } else if (this.sliderShow) {
        this.sliderShow = false;
      }
      window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
    },
    closeSlider() {
      this.sliderShow = false;
    },
    // 下载音频
    downloadFile(e) {
      if (
        localStorage.getItem("token") == null &&
        window.location.href.indexOf("/home") > -1
      ) {
        // 在首页未登录状态
        this.$message.warning("请登录");
        this.$emit("setDownLoadContainerShow");
        return;
      }
      this.$emit("setDownLoadContainerShow");
    },
    collectSongOrNot(str) {
      if (str == "y") {
        // 收藏这首歌曲
        if (
          localStorage.getItem("token") == null &&
          window.location.href.indexOf("/home") > -1
        ) {
          // 在首页未登录状态
          this.$emit("collectionLoginSystm");
        } else {
          this.favoriteOrNot(1);
        }
      } else {
        // 取消收藏这首歌曲
        this.favoriteOrNot(0);
      }
    },
    favoriteOrNot(status) {
      let data = {
        musicId: this.$props.musicId,
        status: status,
      };
      if (status == 0) {
        // 取消收藏
        this.collectionTip = false;
      } else if (status == 1) {
        // 收藏
        this.collectionTip = true;
      }
      let href = window.location.href;
      if (href.indexOf("/home") > -1) {
        axiosPost(FAVORITEORNOT, qs.stringify(data))
          .then((res) => {
            if(window.location.href.indexOf("home") > -1) {
              status == 0 
              ? send_record("collect_cancel_forever", {origin: "home_player"}) 
              : send_record("collect_forever", {origin: "home_player"});
            } else  {
              status == 0 
              ? send_record("collect_cancel_forever", {origin: "collection_player"}) 
              : send_record("collect_forever", {origin: "collection_player"});
            }
            console.log(status == 0 ? "取消收藏成功" : "收藏成功")
            let cancelFavoriteList = localStorage.getItem("cancelFavoriteList");
            if(status == 0) {
                localStorage.setItem("cancelFavoriteList", cancelFavoriteList + ',' + musicId);
            } else {
                if(localStorage.getItem("cancelFavoriteList") == null)
                return
                let arr = cancelFavoriteList.split(",");
                let index = arr.findIndex(items => items == this.$props.musicId);
                if(index > -1) {
                    arr.splice(index, 1);
                }
                localStorage.setItem("cancelFavoriteList", arr.join(','));
            }
          })
          .catch((err) => {
            console.log(status == 0 ? "取消收藏失败" : "收藏失败")
            this.$message.error(status == 0 ? "取消收藏失败" : "收藏失败");
          });
      } else if (href.indexOf("/collection") > -1) {
        this.$emit("collectMusicPlayerToCollect", status, this.$props.musicId)
      }
    },
    changeCollectionTipFalse() {
      this.collectionTip = false;
    },
    changeCollectionTip(id, flag) {
      if (id == this.$props.musicId) {
        this.collectionTip = flag ? true : false;
      }
    },
    changeCollectionTipTrue() {
      this.collectionTip = true;
    },
    // 给音频设置音量
    change(value) {
      this.wavesurfer.setVolume(value / 100);
      window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
    },
    // 音量设置结束，滑块消失
    afterChange() {
      this.sliderShow = false;
    },
    
    changeDownloadORLoading(musicId, flag) {
      //true download false loading
      if(this.$props.musicId == musicId) {
        this.downloadORloading = flag;
      } else {
        this.downloadORloading = true;
      }
      
    },
    // 点击波形图获取时间
    getPosition(e) {
      this.playedTime = (e.offsetX / this.wavesurferWidth) * this.audioDuration;
    },
  },
};
</script>

<style lang="scss">
.player {
  width: calc(100vw - 268px);
  min-width: 1170px;
  user-select: none;
  width: 100%;
  .audioTimePosition {
    margin-left: calc(5% + 13px);
    height: 20px;
    margin-top: -20px;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    .playedTime {
      position: relative;
    }
  }
  .right-bottom {
    display: flex;
    justify-content: center;
    line-height: 50px;
    .bottom1 {
      padding-left: 2.5%;
      padding-right: 2.5%;
      i {
        cursor: pointer;
        font-size: 28px;
      }
    }
    .bottom2 {
      display: flex;
      width: 84.5%;
      .timeLine {
        height: 80px;
        margin-top: -16px;
        position: fixed;
        span {
          position: relative;
          display: inline-block;
          height: 80px !important;
          border: 1px solid rgba(0, 0, 0, 0.3);
        }
      }
      div {
        width: 96.5%;
        height: 42px;
        margin-right: 8px;
        margin-top: 2px;
      }
    }
    .bottom3 {
      width: 214px;
      padding-left: 51px;
      position: relative;
      display: flex;
      .downLoadFile{
        img{
          width: 45px;
        }
      }
      .slider {
        top: -120px;
        padding-bottom: 10px;
        position: absolute;
        margin-left: -3px;
        margin-top: 10px;
        bottom: 120px;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
      }
      span {
        cursor: pointer;
      }
      i {
        font-size: 24px;
        color: #979797;
      }
      i:hover {
        color: #000;
      }
      .middle-span {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
  .burstPoint-content {
    position: relative;
    margin-top: 6px;
    margin-left: calc(5% + 23px);
    .structureTimeArr {
      position: absolute;
      margin-top: -11px;
      height: 2px;
      width: 100%;
      span {
        display: inline-block;
        height: 3px;
        margin-right: 2px;
        background: #456;
      }
      span:nth-child(1) {
        margin-right: -2px;
      }
    }
    .burstPoint-aboutTime {
      .burstPoint-tip {
        position: absolute;
        width: 124px;
        height: 24px;
        margin-top: -42px;
        display: inline-block;
        background: #000000;
        border-radius: 6px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
      .burstPoint-img {
        cursor: pointer;
        z-index: 10 !important;
        display: inline-block;
        position: absolute;
        width: 22px;
        height: 22px;
        background: #ffeaeb;
        margin-top: -8px;
        border-radius: 100%;
        border: 3px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 3px 4px rgba(154, 35, 35, 0.25);
        line-height: 18px;
        i {
          font-size: 12px;
          margin-left: 2px;
          color: #de1c1c;
        }
      }

      .aboutTime-time1 {
        position: relative;
        top: 5px;
        padding: 3px 10px;
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 19px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        i {
          font-size: 13px;
        }
      }
      .aboutTime-time2 {
        cursor: pointer;
        position: relative;
        top: 5px;
        padding: 3px 10px;
        background: #000000;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 19px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #ffffff;
        i {
          font-size: 14px;
        }
        .animationn {
          display: inline-block;
          animation: ani-demo-spin 1.5s linear infinite;
        }
        @keyframes ani-demo-spin {
          from {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>

<style>
/* 滑动条 */
.slider .ant-slider-track {
  background: #000 !important;
}
.slider .ant-slider-handle {
  margin-left: -2px !important;
  width: 8px !important;
  height: 8px !important;
  background: #000 !important;
  border: 2px solid #000 !important;
}
</style>