<template>
    <div class="download-container">
        <div class="download-top">
            <div class="top-part audition-container">
                <div class="top-part-left">
                    <div class="left-title">
                        <div class="box">试听下载</div>
                        <div class="box">带水印，MP3</div>
                    </div>
                </div>
                <div class="top-part-right">
                    <span class="box" v-if="!loginStatus" @click="userLogin">
                        登录
                    </span>
                    <span v-if="loginStatus" @click="AuditionDownloadFile" 
                        class="underLine">
                        MP3
                    </span>
                </div>
            </div>
            <div class="top-part product-container">
                <div class="top-part-left">
                    <div class="left-title">
                        <div class="box">成品下载</div>
                        <div class="box">无水印</div>
                    </div>
                    <div class="surplus-count" 
                        :style="userGrade == 0 ? 'background:  rgba(84, 144, 244, 0.5);' 
                        :userGrade == 1 ? 'background: rgba(47, 170, 74, 0.5);'
                        :userGrade == 2 ? 'background: rgba(76, 104, 251, 0.50);'
                        : 'background: rgba(251, 171, 76, 0.5);'">
                        <span class="box" v-if="userGrade != -1">本月剩余：{{downLoadNumber}}</span>
                        <span class="box" v-if="userGrade == -1">暂无权限</span>
                    </div>
                </div>
                <div class="top-part-right">
                    <div class="box" v-if="!loginStatus">
                        <span class="box" @click="userLogin">登录</span>
                    </div>
                    <div class="box" v-if="loginStatus">
                        <div class="box" v-if="downLoadNumber > 0 || is_Buy">
                            <span v-if="downLoadNumber > 0"
                                @click="downloadFile('ori_mp3')" 
                                class="underLine">
                                MP3
                            </span>
                            <span v-if="downLoadNumber > 0" 
                                @click="downloadFile('wav')" 
                                class="underLine">
                                WAV
                            </span>
                        </div>
                        <span class="box" v-if="userGrade == 0" 
                            @click="setPaymentCenterShow($event)">
                            会员订阅
                        </span>
                        <span class="box" @click="setPaymentCenterShow($event)" 
                            v-if="!is_Buy">
                            单曲购买
                        </span>
                        <span class="box" style="opacity: 0.5;" v-if="is_Buy && userGrade != 0">已购买</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow-tip" >
            <!-- :style="{'margin-left': 485 - offsetRight + 'px'}" -->
        </div>
    </div>
</template>

<script>
import qs from "qs";
import {send_record} from "../utils/umeng_util";
import { axiosGet, axiosPost } from "../../src/axios.js";
import {
//   downloadHost,
  DOWNLOADFILE,
} from "../../src/api.js";
export default {
    props: {
        musicId: Number,
        wavesurferAdr: String,
        musicName: String,
    },
    watch: {
    },
    data() {
        return {
            offsetRight: 0,
            loginStatus: Boolean, // 登录状态
            // userGrade: Number, // 0仅登录 1普通vip-创作者 2高级vip-专业创作者 9企业会员
            // downLoadNumber: Number,
            is_Buy: true
        }
    },
    computed: {
        userGrade: function() {
            return this.$store.state.userGrade;
        },
        downLoadNumber: function() {
            return this.$store.state.downLoadNumber;
        }
    },
    watch: {
        userGrade: function(val, oldVal) {
            this.judgeLoginStatus()
        }
    },
    beforeMount() {
        this.judgeLoginStatus();
    },
    mounted() {
        this.offsetRight = (window.innerWidth - 308) * 0.03 + 14 + 30 + 14
    },
    activated() {
        this.judgeLoginStatus()
    },
    methods: {
        setIsBuy(flag) {
            this.is_Buy = flag;
        },
        userLogin() {
            this.$emit("collectionLoginSystm")
        },
        setPaymentCenterShow(e) {
            let str = e.target.innerText;
            let paymentSource;
            let downloadWay;
            if(str == "会员订阅") {
                paymentSource = "PayBecomeVip";
                downloadWay = "buy_vip";
            } else if(str == "单曲购买") {
                paymentSource = "PayBuyOnlyOne";
                downloadWay = "single_buy";
            }
            let count = window.location.href.indexOf("home") > -1 
            ? Number(localStorage.getItem("homeBeforeDownloadCount")) 
            : Number(localStorage.getItem("collectionBeforeDownloadCount"));
            send_record("download_pre_download_generate_count",
                {
                    origin: window.location.pathname.slice(1),
                    download_type: downloadWay,
                    count: count
                }
            )
            window.location.href.indexOf("home") > -1 
            ? localStorage.setItem("homeBeforeDownloadCount", 0) 
            : localStorage.setItem("collectionBeforeDownloadCount", 0) 

            this.$emit("closeDownloadContainer",paymentSource);
        },
        AuditionDownloadFile() {
            let count = window.location.href.indexOf("home") > -1 
            ? Number(localStorage.getItem("homeBeforeDownloadCount")) 
            : Number(localStorage.getItem("collectionBeforeDownloadCount"));
            send_record("download_pre_download_generate_count",
                {
                    origin: window.location.pathname.slice(1),
                    download_type: "demo",
                    count: count
                }
            )
            window.location.href.indexOf("home") > -1 
            ? localStorage.setItem("homeBeforeDownloadCount", 0) 
            : localStorage.setItem("collectionBeforeDownloadCount", 0) 
            
            // home 下载试听渲染中没问题 收藏可能this.$props.musicId会被覆盖
            // this.$emit("closeDownloadContainer",this.$props.musicId);
            
            // 都不显示渲染
            this.$emit("closeDownloadContainer",-100);
            const downLoad = async () => {
                const responsePromise = await fetch(this.$props.wavesurferAdr);
                const blob = await responsePromise.blob();
                const objectURL = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = objectURL;
                a.download = this.$props.musicName + ".mp3";
                a.click();
                a.remove();
                // home 下载试听渲染中没问题 收藏可能this.$props.musicId会被覆盖
                // this.$emit("payAudioLoadingOver", this.$props.musicId);
            };
            downLoad();
        },
        downloadFile(str) {
            this.$emit("closeDownloadContainer", this.$props.musicId);
            let count = window.location.href.indexOf("home") > -1 
            ? Number(localStorage.getItem("homeBeforeDownloadCount")) 
            : Number(localStorage.getItem("collectionBeforeDownloadCount"));
            send_record("download_pre_download_generate_count",
                {
                    origin: window.location.pathname.slice(1),
                    download_type: str == 'ori_mp3' ? 'mp3' : 'wav',
                    count: count
                }
            )
            window.location.href.indexOf("home") > -1 
            ? localStorage.setItem("homeBeforeDownloadCount", 0) 
            : localStorage.setItem("collectionBeforeDownloadCount", 0) 
            
            let data = {
                musicId: this.$props.musicId,
                type: str
            }
            axiosPost(DOWNLOADFILE, qs.stringify(data))
            .then((res) => {
                if (res.data.path != null) {
                    if(!this.is_Buy) {
                        this.$store.dispatch("triggerGetDownNum", this.$store.state.downLoadNumber - 1);
                    }
                    localStorage.setItem("downloadMusicID", localStorage.getItem("downloadMusicID") + ',' + this.$props.musicId)
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.href = res.data.path;
                    a.download = this.$props.musicName + "." + (str == 'ori_mp3' ? 'mp3' : 'wav');;
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(res.data.path);
                    this.$emit("payAudioLoadingOver", res.data.music_id)
                }
            })
            .catch((err) => {
                this.$emit("payAudioLoadingOver", -100)
                if (err.data.code == -1) {
                    this.$message.error(err.data.msg);
                } else {
                    this.$message.error( "未知错误,请重新尝试!");
                }
            });
        },
        judgeLoginStatus() {
            if(localStorage.getItem("token") == null) {
                this.loginStatus = false;
            } else {
                this.loginStatus = true;
            }
            // 获取用户是否vip && 是否有mp3的下载次数
            // this.userGrade && this.downLoadMp3Number 
        }
    }
}
</script>

<style lang="scss" scoped>
.download-container{
    z-index: 3;
    width: 485px;
    height: 196px;
    color: white;
    font-family: PingFang SC;
    // border: 1px solid red;
    .download-top{
        padding: 10px 28px;
        width: 485px;
        height: 182px;
        border-radius: 28px;
        // background: rgba(0, 0, 0, 0.8);
        background: rgba(0, 0, 0, 1);
        .top-part {
            height: 81px;
            display: flex;
            justify-content: space-between;
            .top-part-left{
                display: flex;
                justify-content: space-between;
                .left-title{
                    div:nth-child(1) {
                        margin-top: 14px;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 28px;
                        letter-spacing: 0.025em;
                        color: #FFFFFF;
                    }
                    div:nth-child(2) {
                        margin-top: 4px;
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.025em;
                        color: #FFFFFF;
                        opacity: 0.7;
                    }
                }
                .surplus-count{
                    padding: 0 4px;
                    height: 21px;
                    margin-top: 18px;
                    margin-left: 3px;
                    font-size: 12px;
                    line-height: 21px;
                    // background: rgba(84, 144, 244, 0.5);
                    border-top-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }
            .top-part-right{
                font-size: 18px;
                opacity: 0.9;
                line-height: 81px;
                >div{
                    display: flex;
                }
                span{
                    margin-left: 16px;
                    cursor: pointer;
                }
                .underLine{
                    border-bottom: 1px solid #fff;
                }
            }
        }
    }
    .arrow-tip{
        width: 0;
        height: 0;
        margin-left: 365px;
        // border-top: 14px solid rgba(0, 0, 0, 0.8);
        border-top: 14px solid rgba(0, 0, 0, 1);
        border-right: 14px solid transparent;
        border-left: 14px solid transparent;
    }
}
</style>