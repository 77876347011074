var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"player"},[_c('p',{staticClass:"audioTimePosition"},[_c('span',{staticClass:"playedTime",style:({ left: (_vm.playedTime / _vm.audioDuration) * _vm.wavesurferWidth + 'px' }),attrs:{"id":"playedTime"}},[_vm._v(" "+_vm._s(_vm._f("delayDuration")(_vm.playedTime))+" ")])]),_c('div',{staticClass:"right-bottom"},[_c('div',{staticClass:"bottom1"},[(_vm.playOrNot)?_c('i',{staticClass:"iconfont icon-bofang",staticStyle:{"font-size":"24px"},on:{"click":_vm.playAudio}}):_vm._e(),(!_vm.playOrNot)?_c('i',{staticClass:"iconfont icon-liebiaozanting",staticStyle:{"font-size":"24px"},on:{"click":_vm.playAudio}}):_vm._e()]),_c('div',{staticClass:"bottom2"},[_c('p',{staticClass:"timeLine"},[_c('span',{style:({
            left: (_vm.playedTime / _vm.audioDuration) * _vm.wavesurferWidth - 2 + 'px',
          })})]),_c('div',{staticStyle:{"z-index":"0 !important"},attrs:{"id":"wavesurfer"},on:{"click":function($event){return _vm.getPosition($event)}}}),_c('span',[_vm._v(_vm._s(_vm._f("delayDuration")(_vm.audioDuration)))])]),_c('div',{staticClass:"bottom3",staticStyle:{"z-index":"9 !important"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sliderShow),expression:"sliderShow"}],staticClass:"slider",staticStyle:{"height":"114px"}},[_c('a-slider',{attrs:{"vertical":"","default-value":50},on:{"change":_vm.change,"afterChange":_vm.afterChange}})],1),_c('span',{on:{"click":_vm.showSlider}},[_c('i',{staticClass:"iconfont icon-icon_vio_24",staticStyle:{"z-index":"10 !important"}})]),(!_vm.collectionTip)?_c('span',{staticClass:"middle-span",on:{"click":function($event){return _vm.collectSongOrNot('y')}}},[_c('i',{staticClass:"iconfont icon-icon_leftbar_collect_unselect_241",staticStyle:{"font-size":"22px","z-index":"10 !important"}})]):_vm._e(),(_vm.collectionTip)?_c('span',{staticClass:"middle-span",on:{"click":function($event){return _vm.collectSongOrNot('n')}}},[_c('i',{staticClass:"iconfont icon-icon_leftbar_collect_select_241",staticStyle:{"font-size":"22px","color":"#d36666","z-index":"10 !important"}})]):_vm._e(),_c('span',{staticClass:"downLoadFile",on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile($event)}}},[(_vm.downloadORloading)?_c('i',{staticClass:"iconfont icon-icon_playbar_download_24",staticStyle:{"z-index":"10 !important"}}):_vm._e(),(!_vm.downloadORloading)?_c('img',{attrs:{"src":require("../assets/audioLoading.gif")}}):_vm._e()])])]),(_vm.burstPointTime > 0)?_c('div',{staticClass:"burstPoint-content",style:({ width: _vm.wavesurferWidth + 'px' })},[_c('div',{staticClass:"structureTimeArr"},_vm._l((_vm.structureTimearr),function(item,index){return _c('span',{key:index,style:({
          width:
            ((_vm.structureTimearr[index] - _vm.structureTimearr[index - 1]) /
              _vm.audioDuration) *
              _vm.wavesurferWidth -
            2 +
            'px',
        })})}),0),_c('div',{staticClass:"burstPoint-aboutTime"},[(_vm.burstPointTip)?_c('p',{staticClass:"burstPoint-tip"},[_vm._v("拖动修改高能点位置")]):_vm._e(),_c('span',{directives:[{name:"drag",rawName:"v-drag"}],staticClass:"burstPoint-img",style:({ left: _vm.leftLocation - 11 + 'px' }),on:{"mouseover":_vm.changeBurstPointTipOver,"mouseleave":function($event){_vm.burstPointTip = false}}},[_c('i',{staticClass:"iconfont icon-shixinhuomiao"})]),_c('br'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.burstPointTimeTip & _vm.playOrNot2),expression:"burstPointTimeTip & playOrNot2"}],staticClass:"aboutTime-time1",style:({ left: _vm.leftLocation - 35 + 'px' })},[_c('i',{staticClass:"iconfont icon-shijian"}),_vm._v(" "+_vm._s(_vm._f("delayDuration")(_vm.burstPointtime))+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.burstPointTimeTip),expression:"!burstPointTimeTip"}],staticClass:"aboutTime-time2",style:({ left: _vm.leftLocation - 48 + 'px' }),on:{"click":_vm.createMusic}},[_vm._m(0),_vm._v(" 重新渲染 ")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{attrs:{"id":"animationn"}},[_c('i',{staticClass:"iconfont icon-zhongxin1"})])
}]

export { render, staticRenderFns }