export function checkOld(style, phrases) {
  let old_phrases = JSON.parse(sessionStorage.getItem(style)) || []
  for (let ia = 0; ia < old_phrases.length; ia++) {
    if (phrases == old_phrases[ia]) {
      return true;
    }
  }
  return old_phrases
}
export function addOld(_tid, phrases) {
  let old_phrases = JSON.parse(sessionStorage.getItem(_tid)) || []
  old_phrases.push(phrases)
  sessionStorage.setItem(_tid, JSON.stringify(old_phrases));
}
export function slice_calc(nodes, _bpm, _beat_per_phrase) {

  let _phrase_slices = {};
  let phrase_start, phrase_end
  let SAMPLE_RATE = 44100
  let sample_per_phrase = Math.floor(
    Math.round(((SAMPLE_RATE * 60) / _bpm) * _beat_per_phrase)
  );
  let nodes_slice = nodes.slice(1, -2);
  for (let i = 0; i < nodes_slice.length; i++) {
    phrase_start = Math.floor(Math.round(i * sample_per_phrase));
    phrase_end = Math.floor(Math.round((i + 1) * sample_per_phrase));
    if (nodes_slice[i] in _phrase_slices) {
      _phrase_slices[nodes_slice[i]].push([phrase_start, phrase_end]);
    } else {
      _phrase_slices[nodes_slice[i]] = [[phrase_start, phrase_end]];
    }
  }
  return _phrase_slices
}
// 新高能点计算过程
export function data_process_new(duration, res, input_this_data) {
  let jsnx = require("jsnetworkx");
  let that = input_this_data
  that.offsetSpeed = Math.round(that.offsetSpeed * 1000000) / 1000000
  duration = Math.round(duration * 1000000) / 1000000
  let input_duration = duration
  duration = duration * that.offsetSpeed 
  let boom_list = res.data.boom_list_data;
  // 需要的后端传参
  let _bpm, _beat_per_phrase, nodes, links, _ending_slices, _tid;
  // 计算过程中的数据
  let _beats, _bars, _phrases, bar_per_phrase, curr_node, _graph;
  let p_num = 0
  let B_p_num = 0
  let _phrase_slices = {};
  let repetition = 0;  // 同段落重复出现数
  // 重算数据
  let re_calc = true
  let re_calc_num = 0
  let less20;
  let endPhrases = ["S"];
  let phrases = []
  let beforePhrases = []; // 计算结束的数据

  if (!Array.prototype.derangedArray) {
    Array.prototype.derangedArray = function () {
      for (
        var j, x, i = this.length;
        i;
        j = parseInt(Math.random() * i),
        x = this[--i],
        this[i] = this[j],
        this[j] = x
      );
      return this;
    };
  }
  _bpm = res.data._bpm;
  _beat_per_phrase = res.data._beat_per_phrase;
  _ending_slices = res.data._ending_slices;
  _tid = res.data._tid;
  nodes = res.data.nodes;
  links = res.data.links;
  that.localPhraseTime = ((60 / _bpm) * _beat_per_phrase) / that.offsetSpeed;
  bar_per_phrase = _beat_per_phrase / 4
  // 主体乐句数据准备
  _beats = Math.floor((_bpm / 60) * duration);
  _bars = Math.floor(_beats / 4);
  if (_beats % 4 == 0) {
    _bars -= 1;
  }
  _phrases = Math.floor(_bars / bar_per_phrase);
  if (_phrases < 2) {
    throw  new Error('高能点段落数据错误，请重新生成')
  }
  while (re_calc && re_calc_num < 20) {
    endPhrases = ["S"];
    phrases = [];
    beforePhrases = [];
    less20 = true;
    if (that.localFirstBurst && that.showBurstPoint) {
      // 第一次生成高能点数据
      if ("boom_list_data" in res.data) {
        let value =
          boom_list[Math.round(Math.random() * (boom_list.length - 1))];
        that.localBurstArr = value.split("-");
      } else {
        throw new Error('高能点数据错误，请重新生成')
      }
      that.localBurstIndex = Math.floor(Math.random() * (_phrases / 2));
      that.localPhraseBeforeBurst = that.localBurstIndex;
      that.beforePhraseTime = that.localPhraseTime;
    }
    _phrase_slices = slice_calc(nodes, _bpm, _beat_per_phrase)
    p_num = _phrases - that.localPhraseBeforeBurst - 1;
    B_p_num = that.localPhraseBeforeBurst + 1;
    if('startHalf' in that ) {
      if (that.startHalf==2) {
        B_p_num += 1
      } else if(that.startHalf==1){
        p_num+=1
      }
    }
    console.log('p_num，B_p_num，_phrases', p_num, B_p_num, _phrases);
    _graph = new jsnx.DiGraph();
    _graph.addEdgesFrom(links);
    if (B_p_num < 0 || p_num < 0) {
      console.log(B_p_num, p_num);
      throw  new Error('爆点拖动位置不支持')
    }
    // 
    let b_iter_lists = [[that.localBurstArr[0]]]
    let b_curr_pointer = [0]
    let b_dead_end; //段前循环计算数据
    let iter_lists = [[that.localBurstArr[1]]]
    let curr_pointer = [0]
    let dead_end; //循环计算数据
    let aaa = 0;
    // 
    if (B_p_num > 0) {
      let b_next_list;
      while (beforePhrases.length < B_p_num && b_iter_lists.length > 0) {
        if (aaa > 500) {
          // debugger
          // console.log("进入死循环");
          break;
        }
        aaa = aaa + 1;
        // 选择节点
        curr_node = b_iter_lists[b_iter_lists.length - 1][b_curr_pointer[b_curr_pointer.length - 1]];
        if (beforePhrases.length > 0 && beforePhrases[beforePhrases.length - 1] == curr_node) {
          repetition += 1;
        } else {
          repetition = 0;
        }
        beforePhrases.push(curr_node);
        if (beforePhrases.length <= B_p_num) {
          // 导入下一节点路径
          let local_curr_node_power;
          if (curr_node == undefined) {
            b_dead_end = true;
          } else {
            b_next_list = _graph.predecessors(curr_node);
            local_curr_node_power = parseInt(curr_node.split("_")[1]);
          }
          if (b_next_list.length == 0) {
            b_dead_end = true;
          } else {
            b_dead_end = false;
          }
          if (!b_dead_end) {
            b_next_list.derangedArray(); //随机打乱顺序
            let b_tmp_next_list = [];
            for (let ii = 0; ii < b_next_list.length; ii++) {
              if (!(Math.abs(parseInt(b_next_list[ii].split("_")[1]) - local_curr_node_power) < 20 && less20)
              ) {
                less20 = false;
              }
              b_tmp_next_list.push(b_next_list[ii]);
            }
            b_iter_lists.push(b_tmp_next_list);
            b_curr_pointer.push(0);
          }
        }
        if (repetition >= 2) {
          b_dead_end = true;
        }
        if (b_dead_end || (beforePhrases.length > B_p_num && _graph.predecessors(curr_node).indexOf("S") == -1)) {
          beforePhrases.pop();
          while (b_iter_lists.length > 0 && b_curr_pointer[b_curr_pointer.length - 1] >= b_iter_lists[b_iter_lists.length - 1].length - 1) {
            beforePhrases.pop();
            b_iter_lists.pop();
            b_curr_pointer.pop();
          }
          if (b_curr_pointer.length > 0) {
            let tmp_ = b_curr_pointer.pop();
            b_curr_pointer.push(tmp_ + 1);
          }
        }
      }
    } else {
      re_calc = true;
      re_calc_num += 1;
    }

    if (p_num > 0) {
      let n_repetition = 0;  // 同段落重复出现数
      let next_list;
      while (phrases.length < p_num && iter_lists.length > 0) {
        // 选择节点
        curr_node = iter_lists[iter_lists.length - 1][curr_pointer[curr_pointer.length - 1]];
        if (phrases.length > 0 && phrases[phrases.length - 1] == curr_node) {
          n_repetition += 1;
        } else {
          n_repetition = 0;
        }
        phrases.push(curr_node);
        if (phrases.length <= p_num) {
          // 导入下一节点路径
          next_list = _graph.successors(curr_node);
          if (next_list.length == 0) {
            dead_end = true;
          } else {
            dead_end = false;
          }
          if (!dead_end) {
            next_list.derangedArray(); //随机打乱顺序
            iter_lists.push(next_list);
            curr_pointer.push(0);
          }
        }
        if (n_repetition >= 2) {
          dead_end = true;
        }
        if (dead_end || (phrases.length > p_num && _graph.successors(curr_node).indexOf("E") == -1)) {
          phrases.pop();
          while (iter_lists.length > 0 && curr_pointer[curr_pointer.length - 1] >= iter_lists[iter_lists.length - 1].length - 1
          ) {
            phrases.pop();
            iter_lists.pop();
            curr_pointer.pop();
          }
          if (curr_pointer.length > 0) {
            let tmp_ = curr_pointer.pop();
            curr_pointer.push(tmp_ + 1);
          }
        }
      }
    } else {
      re_calc = true;
      re_calc_num += 1;
    }
    if (beforePhrases.length < B_p_num || phrases.length < p_num) {
      re_calc = true;
      re_calc_num += 1;
    } else {
      re_calc = false;
    }

    if (iter_lists.length + b_iter_lists.length == 0) {
      re_calc = true;
      re_calc_num += 1;
    }
    endPhrases.push(...beforePhrases.reverse());
    endPhrases.push(...phrases);
    if (!re_calc) {
      let old_phrases = checkOld(_tid, phrases);
      if (old_phrases == true) {
        re_calc_num += 1;
      } else {
        addOld(_tid, endPhrases);
        re_calc = false;
      }
    }
  }
  if (re_calc) {
    re_calc = false;
    re_calc_num = 0;
    let msgg = '计算数据错误，请重新生成'
    if (!that.localFirstBurst) {
      msgg = '爆点拖动位置计算失败，请重新生成'
    }
    throw  new Error(msgg)
  }
  
  let ending_slice
  if (endPhrases.length==0) {
    _ending_slices.derangedArray(); //随机打乱顺序
    ending_slice =  _ending_slices[0]
  }else{
    ending_slice = endPhrases[endPhrases.length-1]
  }
  let datas = {
    _bpm: _bpm,
    beat_per_phrase: _beat_per_phrase,
    ending: ending_slice,
    duration: input_duration,
    _tid: _tid,
  };
  datas["slices"] = JSON.stringify(_phrase_slices);
  datas["phrases"] = endPhrases;
  datas['BurstArr'] = that.localBurstArr
  datas['BurstIndex'] = that.localBurstIndex
  that.localLabelId = _tid;
  return { 'datas': datas, 'this_data': that };
}
